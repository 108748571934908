import app, { db } from "../config/firebase";
import moment from "moment";

// Checks for validity
export const checkValidity = (value, rules) => {
  let isValid = true;

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.regex) {
    isValid = rules.regex.test(value) && isValid;
  }

  return isValid;
};

// Anon Login
export const anonLogin = (dispatch) => {
  app
    .auth()
    .signInAnonymously()
    .then((resp) => {
      console.log("Login successful");
      dispatch({ type: "SET_LOADING", loading: false });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: "SET_LOADING", loading: false });
    });
};

// Submits the phone number to firebase and sets the confirmation result
// TODO: Add validation
export const submitPhoneNumber = (dispatch, numTel, appVerifier) => {
  let telephone = `+262${numTel.value}`;
  app
    .auth()
    .signInWithPhoneNumber(telephone, appVerifier)
    .then((confirmationResult) => {
      // window.confirmationResult = confirmationResult;
      // setConfResult(confirmationResult);
      dispatch({ type: "SET_CONFRESULT", confResult: confirmationResult });
      dispatch({ type: "SET_SMSSHOW" });
      dispatch({ type: "SET_LOADING", loading: false });
      console.log(
        "Phone num subbmitted, confirmationResult :",
        confirmationResult
      );
    })
    .catch((error) => {
      console.log("Error while submitting phone number", error);
    });
};

// Sumbits the code recieved by sms and logs the user in
// TODO: Add validation
export const submitCodeSMS = (dispatch, codeSMS, confResult) => {
  dispatch({ type: "SET_LOADING", loading: true });
  confResult
    .confirm(codeSMS.value)
    .then((result) => {
      console.log("SMS code subbmitted : ", result);
      dispatch({
        type: "SET_ISNEWUSER",
        isNewUser: result.additionalUserInfo.isNewUser,
      });
      dispatch({ type: "SET_LOADING", loading: false });
    })
    .then((resp) => console.log("-"))
    .catch((error) => {
      console.log("Error while submitting Code sms", error);
    });
};

// Add new locataire
// TODO: add time
export const addNewLoc = (refLoc, locataireID) => {
  const newLoc = {
    refLoc,
    locataireID,
    dateCreation: moment().format(),
  };

  db.collection("locataires")
    .doc(locataireID)
    .set(newLoc)
    .then((resp) => {
      console.log("[New user added successfully]");
    })
    .catch((err) => {
      console.log("Error while adding new user: ", err);
    });
};
