import React from "react";
import classes from "./Modal.module.css";
import Backdrop from "../Backdrop/Backdrop";

const modal = (props) => {
  return (
    <React.Fragment>
      <Backdrop show={props.show} clicked={props.clicked} />
      <div
        style={{
          transform: props.show ? "translateY(-20vh)" : "translateY(-100vh)",
          opacity: props.show ? "1" : "0",
        }}
        onClick={props.clicked}
        className={classes.Modal}
      >
        {props.children}
      </div>
    </React.Fragment>
  );
};

export default modal;
