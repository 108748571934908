import React from "react";

export const travauxCardsData = [
  {
    id: "c5",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Un nom pour l’opération",
    titleWeak: "Victoria !",
    description:
      "La résidentialisation de l’opération victoria va être réalisée en trois îlots distincts. La SHLMR souhaite rebaptiser votre résidence. A vous de choisir.",
    thumbnail: "c5_q1rep5.png",
    imgSrc: "c5_q1rep5.png",
    planMasse: "",
    tags: ["personnalisable", "résidentialisation"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title:
        "Seriez-vous prêt à vous impliquer dans la création ou l’entretien d’un jardin partagé ?",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title: "Quel nom souhaitez-vous donner à l’opération ?",
        options: [
          {
            label: "Prénoms (Octavia, Andréa, Clara)",
            value: "q1rep1",
            results: 14,
            resultsIlotOne: 4,
            resultsIlotTwo: 3,
            resultsIlotThree: 2,
          },
          {
            label: "Îles disparues (Sabrina, Nova, Rehana)",
            value: "q1rep2",
            results: 3,
            resultsIlotOne: 0,
            resultsIlotTwo: 1,
            resultsIlotThree: 1,
          },
          {
            label: "Astronomie (Solstice, Equinoxe, Zenith",
            value: "q1rep3",
            results: 6,
            resultsIlotOne: 0,
            resultsIlotTwo: 1,
            resultsIlotThree: 3,
          },
          {
            label: "Bateaux (Galion, Corvette, Drakkar)",
            value: "q1rep4",
            results: 2,
            resultsIlotOne: 0,
            resultsIlotTwo: 0,
            resultsIlotThree: 0,
          },
          {
            label: "Victoria",
            value: "q1rep5",
            results: 122,
            resultsIlotOne: 34,
            resultsIlotTwo: 37,
            resultsIlotThree: 35,
          },
        ],
        value: "q1rep1",
        winner: "q1rep5",
        winnerIlotOne: "q1rep5",
        winnerIlotTwo: "q1rep5",
        winnerIlotThree: "q1rep5",
        totResults: 147,
        totResultsIlotOne: 38,
        totResultsIlotTwo: 42,
        totResultsIlotThree: 41,
      },
    ],
    comments: ["Opération NOVA"],
    answers: [],
    comment: "",
    noticeOne: "",
    noticeTwo: "",
  },
  {
    id: "c1",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Travaux de salle de bain",
    titleWeak: "et varangue.",
    description:
      "Votre salle de bain fait peau neuve ! La zone séchoir est transférée en varangue pour plus d’espace et de rangements. Pour le reste, à vous de décider !",
    thumbnail: "c1_q1rep3_q2rep1.png",
    imgSrc: "c1_q1rep3_q2rep1.png",
    planMasse: "",
    tags: ["personnalisable", "réhabilitation"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radioImage",
        title:
          "Quelle couleur souhaitez-vous pour votre bande mural de salle de bain ?",
        options: [
          {
            label: "Maron",
            thumbnail: "Mini_SalleDebain_1.jpg",
            value: "q1rep1",
            results: 36,
            resultsIlotOne: 13,
            resultsIlotTwo: 8,
            resultsIlotThree: 6,
          },
          {
            label: "Vert",
            thumbnail: "Mini_SalleDebain_2.jpg",
            value: "q1rep2",
            results: 24,
            resultsIlotOne: 10,
            resultsIlotTwo: 5,
            resultsIlotThree: 5,
          },
          {
            label: "Bleu",
            thumbnail: "Mini_SalleDebain_3.jpg",
            value: "q1rep3",
            results: 111,
            resultsIlotOne: 22,
            resultsIlotTwo: 39,
            resultsIlotThree: 34,
          },
        ],
        value: "q1rep1",
        winner: "q1rep3",
        totResults: 171,
        winnerIlotOne: "q1rep3",
        winnerIlotTwo: "q1rep3",
        winnerIlotThree: "q1rep3",
        totResultsIlotOne: 45,
        totResultsIlotTwo: 52,
        totResultsIlotThree: 45,
      },
      {
        id: "q2",
        type: "radio",
        title:
          "Quel choix de rangement souhaitez-vous pour votre logement suite au déplacement de la zone séchoir en varangue ?",
        options: [
          {
            label:
              "Un placard dans le renfoncement à côté des WC et un placard dans la salle de bain avec le ballon d’eau chaude à l’intérieur.",
            value: "q2rep1",
            results: 162,
            resultsIlotOne: 45,
            resultsIlotTwo: 49,
            resultsIlotThree: 42,
          },
          {
            label: "Aucun rangement",
            value: "q2rep2",
            results: 9,
            resultsIlotOne: 0,
            resultsIlotTwo: 3,
            resultsIlotThree: 3,
          },
        ],
        value: "q2rep1",
        winner: "q2rep1",
        totResults: 171,
        winnerIlotOne: "q2rep1",
        winnerIlotTwo: "q2rep1",
        winnerIlotThree: "q2rep1",
        totResultsIlotOne: 45,
        totResultsIlotTwo: 52,
        totResultsIlotThree: 45,
      },
    ],
    comments: [
      "J'aimerais garder la zone de séchoir.",
      "Je souhaite des douches italiennes ",
      "lave main dans les toilettes",
      "toilette separer de la salle de bain",
      "ou se trouve la fenêtre pour aéré tout ça ",
      "Salle de bain ",
      "Douche PMR déjà réalisée y compris VW dans la même pièce. Prévoir pack senior. ",
    ],
    answers: [
      "Cela est impossible car votre zone de séchoir est devenue votre douche. Cela a été décidé ainsi afin de favoriser la ventilation naturelle de votre SDB et supprimer les problématiques liées aux moisissures et air vicié.",
      "Il n’est pas prévu de douche à l’italienne à proprement parlé, mais la solution proposée s’en rapproche fortement à la seule différence qu’elle comporte un rebord servant à retenir l’eau dans la zone de douche et mesure approximativement 10cm de haut.",
      "Il n’est pas prévu de lave main dans les toilettes car ceux-ci sont trop petit et se trouvent juste à côté de la SDB.",
      "Nous vous confirmons que les toilettes sont bien séparés de la SDB par une cloison et le resterons.",
      "La fenêtre de la SDB se trouve en façade sur le mur non représenté situé en face de la poire de douche. Il s’agira d’une jalousie et non plus d’une fenêtre, ceci dans le but de favoriser la ventilation naturelle de votre SDB (peut rester ouverte même si vous n’êtes pas chez vous).",
      "NA",
      "Logement concerné situé en RDC et à identifier. Un pack d’ergonomie sénior sera à prévoir (barres d’appuis, siège escamotable, WC surélevé…",
    ],
    comment: "",
    noticeOne: "*Images à titre indicatif.",
    noticeTwo: "**Choix ramené à la majorité à l'échelle de l'îlot concerné.",
  },
  {
    id: "c2",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Travaux des sols",
    titleWeak: "de votre logement.",
    description:
      "Les architectes souhaitents revoir la nature des sols de votre logement et de votre varangue. A vous de décider parmi les options suivantes.",
    thumbnail: "c2_q1rep2_q2rep1.png",
    imgSrc: "c2_q1rep2_q2rep1.png",
    planMasse: "",
    tags: ["personnalisable", "réhabilitation"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radioImage",
        title:
          "Quelle couleur souhaitez-vous pour le sol de votre logement (hors parties carrelées) ?",
        options: [
          {
            label: "Bois blanchi",
            thumbnail: "Mini_SolLOG1.jpg",
            value: "q1rep1",
            results: 44,
            resultsIlotOne: 12,
            resultsIlotTwo: 10,
            resultsIlotThree: 13,
          },
          {
            label: "Bois vielli",
            thumbnail: "Mini_SolLOG2.jpg",
            value: "q1rep2",
            results: 63,
            resultsIlotOne: 19,
            resultsIlotTwo: 19,
            resultsIlotThree: 20,
          },
          {
            label: "Bois naturel",
            thumbnail: "Mini_SolLOG3.jpg",
            value: "q1rep3",
            results: 56,
            resultsIlotOne: 14,
            resultsIlotTwo: 21,
            resultsIlotThree: 11,
          },
        ],
        value: "q1rep1",
        winner: "q1rep2",
        totResults: 163,
        winnerIlotOne: "q1rep2",
        winnerIlotTwo: "q1rep3",
        winnerIlotThree: "q1rep2",
        totResultsIlotOne: 45,
        totResultsIlotTwo: 50,
        totResultsIlotThree: 44,
      },
      {
        id: "q2",
        type: "radioImage",
        title: "Quelle couleur souhaitez-vous pour le sol de votre varangue ?",
        options: [
          {
            label: "Gris",
            thumbnail: "Mini_SolLOGA.jpg",
            value: "q2rep1",
            results: 85,
            resultsIlotOne: 18,
            resultsIlotTwo: 22,
            resultsIlotThree: 25,
          },
          {
            label: "Gris moyen",
            thumbnail: "Mini_SolLOGB.jpg",
            value: "q2rep2",
            results: 78,
            resultsIlotOne: 27,
            resultsIlotTwo: 28,
            resultsIlotThree: 19,
          },
        ],
        value: "q2rep1",
        winner: "q2rep1",
        totResults: 163,
        winnerIlotOne: "q2rep2",
        winnerIlotTwo: "q2rep2",
        winnerIlotThree: "q2rep1",
        totResultsIlotOne: 45,
        totResultsIlotTwo: 50,
        totResultsIlotThree: 44,
      },
    ],
    comments: [
      "Pour le balcon , elle demande une couleur un peu plus clair ( beige)",
      "Souhaite conserver le carrelage dans le logement, hors sdb. ",
      "Souhaite du carrelage dans tout le logement ",
      "Carrelage déjà en place: sera obligatoirement cassé.",
      "Couleurs gris pour le sol",
    ],
    answers: [
      "Le choix « gris » proposé ressemble en réalité plus à du beige clair qu’à du gris clair.",
      "Une visite avant travaux est prévue dans l’ensemble des logements, en présence de l’architecte, de l’entreprise en charge des travaux et de la SHLMR. Il sera identifié lors de cette visite les spécificités de chaque logement (présence de carrelage partout, d’une cuisine équipée, de placards, etc…). Des décisions d’adaptations seront alors prisent d’un commun accord entre les parties avant le lancement des travaux.",
      "Ceci n’est pas prévu afin d’éviter des problématiques d’acoustique (bruits d’impact trop important entre niveaux du bâtiment) et d’intervention en site occupé.",
      "Idem question 2 ci-dessous.",
    ],
    comment: "",
    noticeOne: "*Images à titre indicatif.",
    noticeTwo: "**Choix ramené à la majorité à l'échelle de l'îlot concerné.",
  },
  {
    id: "c3",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Séparation Salon",
    titleWeak: "et cuisine.",
    description:
      "Les architectes souhaitent repenser la relation entre le salon et la cuisine. A vous de décider parmi les options suivantes.",
    thumbnail: "c3_q1rep1.png",
    imgSrc: "c3_q1rep1.png",
    planMasse: "",
    tags: ["personnalisable", "réhabilitation"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radioImage",
        title:
          "Quel type de séparation entre la cuisine et salon souhaitez-vous ?",
        options: [
          {
            label: "Ouvert",
            thumbnail: "Mini_SeparationSalonCuisine1.jpg",
            value: "q1rep1",
            results: 77,
            resultsIlotOne: 17,
            resultsIlotTwo: 25,
            resultsIlotThree: 23,
          },
          {
            label: "Semi-ouvert",
            thumbnail: "Mini_SeparationSalonCuisine3.jpg",
            value: "q1rep2",
            results: 26,
            resultsIlotOne: 10,
            resultsIlotTwo: 9,
            resultsIlotThree: 4,
          },
          {
            label: "Fermé",
            thumbnail: "Mini_SeparationSalonCuisine2.jpg",
            value: "q1rep3",
            results: 62,
            resultsIlotOne: 18,
            resultsIlotTwo: 17,
            resultsIlotThree: 18,
          },
        ],
        value: "q1rep1",
        winner: "q1rep1",
        totResults: 165,
        winnerIlotOne: "q1rep3",
        winnerIlotTwo: "q1rep1",
        winnerIlotThree: "q1rep1",
        totResultsIlotOne: 45,
        totResultsIlotTwo: 51,
        totResultsIlotThree: 45,
      },
    ],
    comments: ["Je souhaite une cuisine américaine ou ouverte "],
    answers: [],
    comment: "",
    noticeOne: "*Images à titre indicatif.",
    noticeTwo: "**Choix propre au logement concerné.",
  },
  {
    id: "c4",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Travaux de cuisine",
    titleWeak: "plan de travail et crédence.",
    description:
      "Mettez les petits plats dans les grands en personnalisant certains éléments de votre cuisine! En l’occurence, le plan de travail et la crédence. A vous de choisir.",
    thumbnail: "c4_q1rep1_q2rep1.png",
    imgSrc: "c4_q1rep1_q2rep1.png",
    planMasse: "",
    tags: ["personnalisable", "réhabilitation"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radioImage",
        title:
          "Quelle couleur ou type souhaitez-vous pour la crédence de votre cuisine ?",
        options: [
          {
            label: "Alu brossé",
            thumbnail: "Mini_Cuisine3_AluBrosse.jpg",
            value: "q1rep1",
            results: 64,
            resultsIlotOne: 19,
            resultsIlotTwo: 22,
            resultsIlotThree: 19,
          },
          {
            label: "Granit",
            thumbnail: "Mini_Cuisine1_GRANIT.jpg",
            value: "q1rep2",
            results: 55,
            resultsIlotOne: 18,
            resultsIlotTwo: 14,
            resultsIlotThree: 14,
          },
          {
            label: "Rouge",
            thumbnail: "Mini_Cuisine2_ROUGE.jpg",
            value: "q1rep3",
            results: 43,
            resultsIlotOne: 10,
            resultsIlotTwo: 14,
            resultsIlotThree: 10,
          },
        ],
        value: "q1rep1",
        winner: "q1rep1",
        totResults: 162,
        winnerIlotOne: "q1rep1",
        winnerIlotTwo: "q1rep1",
        winnerIlotThree: "q1rep1",
        totResultsIlotOne: 47,
        totResultsIlotTwo: 50,
        totResultsIlotThree: 43,
      },
      {
        id: "q2",
        type: "radioImage",
        title:
          "Quelle couleur ou type souhaitez-vous pour le plan de travail de votre cuisine?",
        options: [
          {
            label: "Granit",
            thumbnail: "Mini_Cuisine1_GRANIT.jpg",
            value: "q2rep1",
            results: 104,
            resultsIlotOne: 30,
            resultsIlotTwo: 32,
            resultsIlotThree: 25,
          },
          {
            label: "Rouge",
            thumbnail: "Mini_Cuisine2_ROUGE.jpg",
            value: "q2rep2",
            results: 18,
            resultsIlotOne: 6,
            resultsIlotTwo: 6,
            resultsIlotThree: 3,
          },
          {
            label: "Alu brossé",
            thumbnail: "Mini_Cuisine3_AluBrosse.jpg",
            value: "q2rep3",
            results: 40,
            resultsIlotOne: 11,
            resultsIlotTwo: 12,
            resultsIlotThree: 15,
          },
        ],
        value: "q2rep1",
        winner: "q2rep1",
        totResults: 162,
        winnerIlotOne: "q2rep1",
        winnerIlotTwo: "q2rep1",
        winnerIlotThree: "q2rep1",
        totResultsIlotOne: 47,
        totResultsIlotTwo: 50,
        totResultsIlotThree: 43,
      },
    ],
    comments: [
      "ouverture fenêtre manque de lumière laisser la fenêtre comme c'est actuellement ",
    ],
    answers: [
      "L’image peut faire paraitre un manque de lumière. La fenêtre actuelle sera remplacée par une jalousie ceci dans le but de favoriser la ventilation naturelle de votre cuisine (peut rester ouverte même si vous n’êtes pas chez vous).",
    ],
    comment: "",
    noticeOne: "*Images à titre indicatif.",
    noticeTwo: "**Choix ramené à la majorité à l'échelle de l'îlot concerné.",
  },
  {
    id: "c6",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Type de séparation des îlots",
    titleWeak: "Accès véhicule",
    description:
      "L’ accès véhicule des trois îlots de votre résidence seront séparés par des clôtures. A vous de décider parmi les options suivantes.",
    thumbnail: "c6_q1rep1.png",
    imgSrc: "c6_q1rep1.png",
    planMasse: "",
    tags: ["personnalisable", "résidentialisation"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title: "Quel type de clôture souhaitez-vous pour cette séparation ?",
        options: [
          {
            label:
              "Une clôture végétale composée de haies et des enrochements.",
            value: "q1rep1",
            results: 94,
            resultsIlotOne: 31,
            resultsIlotTwo: 24,
            resultsIlotThree: 25,
          },
          {
            label:
              "Une clôture rigide avec des petites ouvertures permettant le passage des piétons et vélos.",
            value: "q1rep2",
            results: 64,
            resultsIlotOne: 13,
            resultsIlotTwo: 26,
            resultsIlotThree: 17,
          },
        ],
        value: "q1rep1",
        winner: "q1rep1",
        totResults: 158,
        winnerIlotOne: "q1rep1",
        winnerIlotTwo: "q1rep2",
        winnerIlotThree: "q1rep1",
        totResultsIlotOne: 44,
        totResultsIlotTwo: 50,
        totResultsIlotThree: 42,
      },
    ],
    comments: [
      "Souhaite une numérotation des places de parking. ",
      "Prévoir une numérotation des places de parking. ",
      "une numérotation des places de parking",
      "rien",
    ],
    answers: [
      "Réponse à voir avec Dino et/ou la gestion locative.",
      "Réponse à voir avec Dino et/ou la gestion locative.",
      "Réponse à voir avec Dino et/ou la gestion locative.",
    ],
    comment: "",
    noticeOne: "*Images à titre indicatif.",
    noticeTwo: "**Choix ramené à la majorité à l'échelle de l'îlot concerné.",
  },
  {
    id: "c7",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "La place centrale",
    titleWeak: "",
    description:
      "Des aménagements et des travaux d’embellissement sont prévus au niveau de la place centrale. A vous de choisir parmi ses options.",
    thumbnail: "c7_q1rep1.png",
    imgSrc: "c7_q1rep1.png",
    planMasse: "",
    tags: ["personnalisable", "résidentialisation"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title: "Parmis ces usages lesquels préferez-vous ?",
        options: [
          {
            label: "Bancs, table d’échec et kiosque",
            value: "q1rep1",
            results: 119,
            resultsIlotOne: 32,
            resultsIlotTwo: 37,
            resultsIlotThree: 33,
          },
          {
            label: "Un boulodrome",
            value: "q1rep2",
            results: 38,
            resultsIlotOne: 12,
            resultsIlotTwo: 13,
            resultsIlotThree: 10,
          },
        ],
        value: "q1rep1",
        winner: "q1rep1",
        totResults: 157,
        winnerIlotOne: "q1rep1",
        winnerIlotTwo: "q1rep1",
        winnerIlotThree: "q1rep1",
        totResultsIlotOne: 44,
        totResultsIlotTwo: 50,
        totResultsIlotThree: 43,
      },
    ],
    comments: [],
    answers: [],
    comment: "",
    noticeOne: "*Images à titre indicatif.",
    noticeTwo: "**Choix ramené à la majorité à l'échelle de l'îlot concerné.",
  },
  {
    id: "c8",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Les traversées piétonnes",
    titleWeak: "des bâtiments",
    description:
      "Des aménagements et des travaux d’embellissement sont prévus sur les traversées piétonnes des bâtiments. A vous de choisir parmi ses options.",
    thumbnail: "c8_q1rep4.png",
    imgSrc: "c8_q1rep4.png",
    planMasse: "",
    tags: ["personnalisable", "résidentialisation"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title: "Parmis ces aménagements lesquels préferez-vous ?",
        options: [
          {
            label: "Fresques sur les murs par des artistes locaux",
            value: "q1rep1",
            results: 35,
            resultsIlotOne: 11,
            resultsIlotTwo: 11,
            resultsIlotThree: 9,
          },
          {
            label: "Végétalisation par des jardinières/chicanes au sol",
            value: "q1rep2",
            results: 20,
            resultsIlotOne: 6,
            resultsIlotTwo: 6,
            resultsIlotThree: 4,
          },
          {
            label: "Mur végétal (lianes + câbles verticaux)",
            value: "q1rep3",
            results: 14,
            resultsIlotOne: 5,
            resultsIlotTwo: 3,
            resultsIlotThree: 4,
          },
          {
            label: "Fresque sur les murs et végétalisation au sol",
            value: "q1rep4",
            results: 52,
            resultsIlotOne: 15,
            resultsIlotTwo: 17,
            resultsIlotThree: 17,
          },
          {
            label: "Végétalisation au sol et mur végétal",
            value: "q1rep5",
            results: 33,
            resultsIlotOne: 7,
            resultsIlotTwo: 13,
            resultsIlotThree: 7,
          },
        ],
        value: "q1rep1",
        winner: "q1rep4",
        totResults: 154,
        winnerIlotOne: "q1rep4",
        winnerIlotTwo: "q1rep4",
        winnerIlotThree: "q1rep4",
        totResultsIlotOne: 44,
        totResultsIlotTwo: 50,
        totResultsIlotThree: 41,
      },
    ],
    comments: ["Mur avec carrelage avec végétalisation risque de moustique"],
    answers: [
      "Je ne comprends pas en quoi la végétation est liée à une problématique de moustiques.",
    ],
    comment: "",
    noticeOne: "*Images à titre indicatif.",
    noticeTwo: "**Choix ramené à la majorité à l'échelle de l'îlot concerné.",
  },
  {
    id: "c9",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Les jardins partagés",
    titleWeak: "",
    description:
      "Votre bailleur prévoit un projet de jardin partagés. Un lieu d’échange, de partage et de culture de produits sain et goûteux qui ne pourra prendre vie qu’avec votre implication.",
    thumbnail: "c9_q1rep1.png",
    imgSrc: "c9_q1rep1.png",
    planMasse: "",
    tags: ["personnalisable", "résidentialisation"],
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Seriez-vous prêt à vous impliquer dans la création ou l’entretien de ce jardin ?",
        options: [
          {
            label: "Oui",
            value: "q1rep1",
            results: 93,
            resultsIlotOne: 28,
            resultsIlotTwo: 31,
            resultsIlotThree: 23,
          },
          {
            label: "Non",
            value: "q1rep2",
            results: 63,
            resultsIlotOne: 16,
            resultsIlotTwo: 19,
            resultsIlotThree: 20,
          },
        ],
        value: "q1rep1",
        winner: "q1rep1",
        totResults: 156,
        winnerIlotOne: "q1rep1",
        winnerIlotTwo: "q1rep1",
        winnerIlotThree: "q1rep1",
        totResultsIlotOne: 44,
        totResultsIlotTwo: 50,
        totResultsIlotThree: 43,
      },
    ],
    comments: [],
    answers: [],
    comment: "",
    noticeOne: "*Images à titre indicatif.",
    noticeTwo: "**Choix ramené à la majorité à l'échelle de l'îlot concerné.",
  },
  {
    id: "nc1",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Travaux de résidentialisation",
    titleWeak: "de toute l’opération Victoria",
    description:
      "La résidentialisation consiste à former 3 ilots distincts afin de les clôturer et de séparer vos résidences du domaine public. Pour accompagner cela, un double système de portail d’accès véhicule et de portillon piéton seront mis en place à chaque entrée d’ilot: au 1 Rue du Lycée et 2 rue Maingard. La rue Victoria quant à elle deviendra privé et à sens unique avec une sortie Rue de la Compagnie. Enfin pour renforcer la sécurité des résidents et prémunire contre les dégradations, véhicules épaves et autres ateliers de mécanique sauvages, des un système de vidéo-surveillance sera mis en place sur les 3 ilôts.",
    jsxDescription: (
      <React.Fragment>
        <p>
          La résidentialisation consiste à former 3 ilots distincts afin de les
          clôturer et de séparer vos résidences du domaine public. Pour
          accompagner cela, un double système de portail d’accès véhicule et de
          portillon piéton seront mis en place à chaque entrée d’ilot: au 1 Rue
          du Lycée et 2 rue Maingard. La rue Victoria quant à elle deviendra
          privé et à sens unique avec une sortie Rue de la Compagnie. Enfin pour
          renforcer la sécurité des résidents et prémunire contre les
          dégradations, véhicules épaves et autres ateliers de mécanique
          sauvages, des un système de vidéo-surveillance sera mis en place sur
          les 3 ilôts.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "⚠️",
        detail: "Impact minime sur l’ensemble de la résidence.",
      },
    ],
    thumbnail: "NC1Thumb.png",
    video: {
      url: "https://youtu.be/zzNEvpQRck8",
      width: 320,
      height: 400,
    },
    planMasse: "PlanMasse_NC1.png",
    planMasseTexte: "L’ensemble de la résidence",
    duration: "6 mois.",
    periode: "2023 (Semestre 1)",
    tags: ["informative", "résidentialisation"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "Non",
      "Ok",
      "ce qui est proposé me parait judicieux",
      "Très bonne idée ",
      "Nn",
      " ? ",
      "Mettre un concierge ",
      "Pour quoi pa plus tôt ",
      "L'entrée des ilots rue maingard pourrait poser problème par rapport au traffic dense lors des sorties d'écoles ",
      "Non",
      "je pense que le sens unique de la rue victoria est une  une solution qui doit etre testée avant d etre validée definitivement",
      "A bien pris connaissance des travaux ",
    ],
    answers: [],
  },
  {
    id: "nc2",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Travaux de voiries,",
    titleWeak: "parkings et cheminements",
    description:
      "Avec ces travaux, c’est l’ensemble des routes, trottoirs, chemins, parkings et accès aux logements qui seront revus. L’idée ici est de faciliter l’accès et les déplacements de tous et notamment les personnes à mobilité réduite: Avec des rampes d’accès adaptées, un éclairage adapté ou encore une signalétique adaptée. Aussi, ces travaux de réfection des cheminements dans votre résidence vont valoriser les promenades piétonnes, les parcours à vélo ou en trotinette. Pour une circulation dans votre résidence, plus apaisée et respectueuse de l’environnement et des résidents.",
    jsxDescription: (
      <React.Fragment>
        <p>
          Avec ces travaux, c’est l’ensemble des routes, trottoirs, chemins,
          parkings et accès aux logements qui seront revus. L’idée ici est de
          faciliter l’accès et les déplacements de tous et notamment les
          personnes à mobilité réduite: Avec des rampes d’accès adaptées, un
          éclairage adapté ou encore une signalétique adaptée. Aussi, ces
          travaux de réfection des cheminements dans votre résidence vont
          valoriser les promenades piétonnes, les parcours à vélo ou en
          trotinette. Pour une circulation dans votre résidence, plus apaisée et
          respectueuse de l’environnement et des résidents.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "⚠️",
        detail:
          "Impact sur tout le site à cause de la réogarnisation des accès.",
      },
      {
        id: 2,
        emoji: "👟",
        detail: "Problème de circulation piétonne.",
      },
      {
        id: 3,
        emoji: "🚘",
        detail: "Probléme de circulation voiture.",
      },
      {
        id: 4,
        emoji: "🅿️",
        detail: "Problème d’accès à certains parking.",
      },
    ],
    thumbnail: "NC2Thumb.png",
    video: {
      url: "https://youtu.be/tgwe1TeJYGg",
      width: 320,
      height: 400,
    },
    planMasse: "PlanMasse_NC2.png",
    planMasseTexte: "L’ensemble des voiries, parkings et cheminements piétons.",
    duration: "9 mois.",
    periode: "2022 (Semestre 2) - 2023 (Semestre 1)",
    tags: ["informative", "résidentialisation"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "Non",
      " faire en sorte que les emplacements de stationnement soient un peu plus larges que ceux qu on a actuellement",
      "C'est juste",
      "Ok bonne initiative ",
      "Numéroté chaque parking a leur apartment ",
      "Aucune idée ",
      "DES GRANDES PLAGES DE PARKING SVP. POUR ÉVITER LES RAYURES ET COUPS DE PORTES DANS NOS VOITURES ",
      "Non",
      "vu ",
    ],
    answers: [],
  },
  {
    id: "nc3",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Les espaces verts",
    titleWeak: "en travaux",
    description:
      "Tous les espaces verts de l’opération seront réaménagés. Un grand effort sera mis dans la mise en valeur des espèces végétales et animales du site. Ce travail précis sur la nature dans votre résidence va permettre de vous faire gagner en confort: thermique d’une part, avec une sensation de fraicheur sur les promenades et dans les logements au RDC; mais aussi visuel car l’alternance des arbres plantés et des plus petites espèces végétalisées vont atténuer l’effet parking trop prononcé dans votre résidence.",
    jsxDescription: (
      <React.Fragment>
        <p>
          Tous les espaces verts de l’opération seront réaménagés. Un grand
          effort sera mis dans la mise en valeur des espèces végétales et
          animales du site. Ce travail précis sur la nature dans votre résidence
          va permettre de vous faire gagner en confort: thermique d’une part,
          avec une sensation de fraicheur sur les promenades et dans les
          logements au RDC; mais aussi visuel car l’alternance des arbres
          plantés et des plus petites espèces végétalisées vont atténuer l’effet
          parking trop prononcé dans votre résidence.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "🅿️",
        detail: "Suppression temporaire de quelques places de parking.",
      },
      {
        id: 2,
        emoji: "🔊",
        detail: "Problème de bruits durant les travaux.",
      },
      {
        id: 3,
        emoji: "💨",
        detail: "Problème de poussières durant les travaux.",
      },
      {
        id: 4,
        emoji: "🌱",
        detail: "Présence de terrassement et de tranchées dans la résidence.",
      },
    ],
    thumbnail: "NC3Thumb.png",
    video: {
      url: "https://youtu.be/aMGvnIQyHew",
      width: 320,
      height: 400,
    },
    planMasse: "PlanMasse_NC3.png",
    planMasseTexte: "L’ensemble des espaces verts communs.",
    duration: "9 mois.",
    periode: "2022 (Semestre 2) - 2023 (Semestre 1)",
    tags: ["informative", "résidentialisation"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "difficile de se faire une idée sans consulter un plan",
      "Je ne mettrais pas d'arbre a proximité des voitures",
      "D accord ",
      "Aucune idée ",
      "Ras",
      "Non",
      "ok",
    ],
    answers: [],
  },

  {
    id: "nc4",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Locaux poubelles",
    titleWeak: "travaux & remplacement.",
    description:
      "Les locaux poubelles actuelles seront remplacés par des locaux plus adaptés au tri sélectif et contribueront ainsi à la préservation de notre île. Ces nouveaux locaux seront fermés et faciles d’usage, puisque des ouvertures adaptées seront prévues pour tous types de déchets: des trappes pour le ménager et les plastiques, des fentes pour le papier et des trous pour le verre! Mais l’esthétique de ces nouveaux locaux n’est pas en reste puisque votre bailleur prévoit leur parfaite intégration avec l’ambiance de la future résidence: place au vert et à la discrétion!",
    jsxDescription: (
      <React.Fragment>
        <p>
          Les locaux poubelles actuelles seront remplacés par des locaux plus
          adaptés au tri sélectif et contribueront ainsi à la préservation de
          notre île. Ces nouveaux locaux seront fermés et faciles d’usage,
          puisque des ouvertures adaptées seront prévues pour tous types de
          déchets: des trappes pour le ménager et les plastiques, des fentes
          pour le papier et des trous pour le verre! Mais l’esthétique de ces
          nouveaux locaux n’est pas en reste puisque votre bailleur prévoit leur
          parfaite intégration avec l’ambiance de la future résidence: place au
          vert et à la discrétion!
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "🅿️",
        detail: "Suppression temporaire de quelques places de parking.",
      },
      {
        id: 2,
        emoji: "🔊",
        detail: "Problème de bruits durant les travaux.",
      },
      {
        id: 3,
        emoji: "💨",
        detail: "Problème de poussières durant les travaux.",
      },
      {
        id: 4,
        emoji: "🗑️",
        detail:
          "Création de locaux poubelles provisoires et peut-être déplacés.",
      },
    ],
    thumbnail: "NC4Thumb.png",
    video: {
      url: "https://youtu.be/9AJXgHEJX5M",
      width: 300,
      height: 400,
    },
    planMasse: "PlanMasse_NC4.png",
    planMasseTexte:
      "Les travaux de transofrmations concernent les locaux poubelles en coeur d’ilot.",
    duration: "9 mois.",
    periode: "2022 (Semestre 2) - 2023 (Semestre 1)",
    tags: ["informative", "résidentialisation"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "Non",
      "ça me convient",
      "Oui c'est bien",
      "Ok",
      " ? ",
      "Déplacer les poubelles, éloigner si possible des bâtiments. Ajouter des Kiosques. Sensibilisation les personnes  concernant le trie des déchets.  ",
      "Aucune idée ",
      "Ras",
      "Je suis d’accord",
      "C est bien",
    ],
    answers: [],
  },
  {
    id: "nc5",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Les toitures tôles",
    titleWeak: "en travaux",
    description:
      "Les toitures en tôles seront remises en état afin de limiter les infiltrations d’eau et améliorer votre confort thermique. Comment ? En recouvrant la toiture d’une peinture étanche contre les infiltrations d’eau, réfléchissante et claire contre les rayons UV du soleil. Nous contribuerons à faire baisser la température des logements situés aux derniers niveaux !",
    jsxDescription: (
      <React.Fragment>
        <p>
          Les toitures en tôles seront remises en état afin de limiter les
          infiltrations d’eau et améliorer votre confort thermique. Comment ? En
          recouvrant la toiture d’une peinture étanche contre les infiltrations
          d’eau, réfléchissante et claire contre les rayons UV du soleil. Nous
          contribuerons à faire baisser la température des logements situés aux
          derniers niveaux !
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "👷",
        detail: "Travaux à la nacelle",
      },
      {
        id: 2,
        emoji: "🔊",
        detail: "Problème de bruits durant les travaux.",
      },
      {
        id: 3,
        emoji: "💨",
        detail: "Problème de poussières durant les travaux.",
      },
      {
        id: 4,
        emoji: "⚠️",
        detail: "Possibilité de chutes d’objets.",
      },
    ],
    thumbnail: "NC5Thumb.png",
    video: {
      url: "https://youtu.be/EfHuNv1JTg8",
      width: 320,
      height: 400,
    },
    planMasse: "PlanMasse_NC5.png",
    planMasseTexte: "L’ensemble des toitures tôles.",
    duration: "6 mois.",
    periode: "2021 (Semestre 2)",
    tags: ["informative", "réhabilitation"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "je pense qu il serait necessaire de remplacer les toles qui sont mal en point il serait judicieux d installer des chauffeaux solaires",
      "Excellent",
      "Ok",
      "Aucune idée ",
      "Tôles très abimées et fragile",
      "Non",
      "vu",
    ],
    answers: [],
  },
  {
    id: "nc6",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Les toitures terrasses",
    titleWeak: "en travaux",
    description:
      "Toutes les toitures-terrasses seront remises en état. Leur étanchéité sera retravaillée pour minimiser les infiltrations d’eau. Repeintes en couleur claire et recouvertes d’un isolant, les nouvelles toitures-terrasses permettront d’éviter la surchauffe pour gagner en confort thermique. Cela concerne notamment les logements situés aux derniers niveaux.",
    jsxDescription: (
      <React.Fragment>
        <p>
          Toutes les toitures-terrasses seront remises en état. Leur étanchéité
          sera retravaillée pour minimiser les infiltrations d’eau. Repeintes en
          couleur claire et recouvertes d’un isolant, les nouvelles
          toitures-terrasses permettront d’éviter la surchauffe pour gagner en
          confort thermique. Cela concerne notamment les logements situés aux
          derniers niveaux.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "👷",
        detail: "Travaux à la nacelle",
      },
      {
        id: 2,
        emoji: "🔊",
        detail: "Problème de bruits durant les travaux.",
      },
      {
        id: 3,
        emoji: "💨",
        detail: "Problème de poussières durant les travaux.",
      },
      {
        id: 4,
        emoji: "⚠️",
        detail: "Possibilité de chutes d’objets.",
      },
    ],
    thumbnail: "NC6Thumb.png",
    video: {
      url: "https://youtu.be/qEh8EbwyRRc",
      width: 320,
      height: 400,
    },
    planMasse: "PlanMasse_NC6.png",
    planMasseTexte: "L’ensemble des toitures tôles.",
    duration: "6 mois.",
    periode: "2021 (Semestre 2)",
    tags: ["informative", "réhabilitation"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "il serait interessant de pouvoir fermer les terrasses avec des volets coulissants",
      "Excellent",
      "Ok",
      " ? ",
      "Mettre un toit sur toit ",
      "Aucune idée ",
      "Ras",
      "Non",
      "c est bien",
    ],
    answers: [],
  },
  {
    id: "nc7",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Les façades",
    titleWeak: "en travaux",
    description:
      "Les architectes prévoient une remise en état et une rénovation des façades de votre immeuble. En plus d’un ravalement total des parties peintes, différents bardages seront installés sur les façades pour votre confort et celui de votre résidence. Ces bardages participeront à la baisse de température des logements concernés. Sur les façades des protections solaires seront aussi mises en place : des casquettes et des joues sur certaines ouvertures protègeront du soleil et apporteront de l’ombre ; des volets persiennes au niveau des chambre et des claustras à ventelles au niveau des varangues. Enfin, les cages d’escalier vont bénéficier d’une protection contre les intempéries tout en favorisant l’apport de lumière de ces espaces.",
    jsxDescription: (
      <React.Fragment>
        <p>
          Les architectes prévoient une remise en état et une rénovation des
          façades de votre immeuble. En plus d’un ravalement total des parties
          peintes, différents bardages seront installés sur les façades pour
          votre confort et celui de votre résidence. Ces bardages participeront
          à la baisse de température des logements concernés. Sur les façades
          des protections solaires seront aussi mises en place : des casquettes
          et des joues sur certaines ouvertures protègeront du soleil et
          apporteront de l’ombre ; des volets persiennes au niveau des chambre
          et des claustras à ventelles au niveau des varangues. Enfin, les cages
          d’escalier vont bénéficier d’une protection contre les intempéries
          tout en favorisant l’apport de lumière de ces espaces.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "👷",
        detail: "Pose d’échaffaudage et travaux à la nacelle.",
      },
      {
        id: 2,
        emoji: "🔊",
        detail: "Problème de bruits durant les travaux.",
      },
      {
        id: 3,
        emoji: "💨",
        detail: "Problème de poussières durant les travaux.",
      },
      {
        id: 4,
        emoji: "⚠️",
        detail: "Possibilité de chutes d’objets.",
      },
    ],
    thumbnail: "NC7Thumb.png",
    video: {
      url: "https://youtu.be/w5KMUtb5iaE",
      width: 320,
      height: 400,
    },
    planMasse: "PlanMasse_NC7.png",
    planMasseTexte: "L’ensemble des façades.",
    duration: "12 mois.",
    periode: "2021 (Semestre 2) - 2022 (Semestre 1)",
    tags: ["informative", "réhabilitation"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "des volets aux chambres seraient les bien venus",
      "Bof",
      "Quand il pleut la véranda est plein d eau 💦 ce vraiment dégoûtant pourquoi pas trouvé des vrai solutions pour les véranda ",
      " ? ",
      "ventelles claustras aurons nous autant de lumière dans nos appartements comme maintenant je demande à voir",
      "Fermeture par code pr tout les entrée dr bâtiment ",
      "Non",
      "ok",
    ],
    answers: [],
  },
  {
    id: "nc8",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Parties communes intérieures",
    titleWeak: "en travaux",
    description:
      "Les architectes prévoient une remise en état et une rénovation des façades de votre immeuble. En plus d’un ravalement total des parties peintes, différents bardages seront installés sur les façades pour votre confort et celui de votre résidence. Ces bardages participeront à la baisse de température des logements concernés. Sur les façades des protections solaires seront aussi mises en place : des casquettes et des joues sur certaines ouvertures protègeront du soleil et apporteront de l’ombre ; des volets persiennes au niveau des chambre et des claustras à ventelles au niveau des varangues. Enfin, les cages d’escalier vont bénéficier d’une protection contre les intempéries tout en favorisant l’apport de lumière de ces espaces.",
    jsxDescription: (
      <React.Fragment>
        <p>
          Les architectes prévoient une remise en état et une rénovation des
          façades de votre immeuble. En plus d’un ravalement total des parties
          peintes, différents bardages seront installés sur les façades pour
          votre confort et celui de votre résidence. Ces bardages participeront
          à la baisse de température des logements concernés. Sur les façades
          des protections solaires seront aussi mises en place : des casquettes
          et des joues sur certaines ouvertures protègeront du soleil et
          apporteront de l’ombre ; des volets persiennes au niveau des chambre
          et des claustras à ventelles au niveau des varangues. Enfin, les cages
          d’escalier vont bénéficier d’une protection contre les intempéries
          tout en favorisant l’apport de lumière de ces espaces.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "🅿️",
        detail:
          "Accès aux halls d'entrée et aux cages d'escaliers fortement impactés de manière ponctuelle",
      },
      {
        id: 2,
        emoji: "🔊",
        detail: "Problème de bruits durant les travaux.",
      },
      {
        id: 3,
        emoji: "💨",
        detail: "Problème de poussières durant les travaux.",
      },
      {
        id: 4,
        emoji: "⚠️",
        detail: "Possibilité de chutes d’objets.",
      },
    ],
    thumbnail: "NC8Thumb.png",
    video: {
      url: "https://youtu.be/dDEX11MPUb4",
      width: 320,
      height: 400,
    },
    planMasse: "PlanMasse_NC8.png",
    planMasseTexte:
      "Halls d'accès aux 33 bâtiments, ensemble des cages d'escaliers.",
    duration: "18 mois.",
    periode: "2022 - 2023 (Semestre 1)",
    tags: ["informative", "réhabilitation"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "surtout changer les portes metalliques d entrée ( soit dit en passant n ont jamais etées changées ne fermant meme pas en ce qui concerne l entrée 167 ) avec un systheme de fermeture pour empecher les personnes etrangeres d y acceder",
      "Bof",
      "Ol",
      " ? ",
      "Aucune idée ",
      "Je suis ravi",
      "super",
    ],
    answers: [],
  },
  {
    id: "nc9",
    type: "nonConcertable",
    layout: "nonConcertableLayoutTwo",
    titleStrong: "Les logements",
    titleWeak: "en travaux",
    description:
      "Tous les logements seront complètement réhabilités : de la porte d’entrée, aux tuyauteries jusqu’aux volets. Faisons un petit tour de votre futur logement ! Toutes les portes seront remplacées (Porte d’entrée et portes d’intérieur), les menuiseries extérieures, les équipements (Eviers, WC, Lavabo, baignoires remplacées par des douches, robinetterie…), les installations électriques (Tableau électrique, interrupteurs, prises, câbles sous goulottes…) et tuyauteries seront également remplacés. Les murs et les plafonds seront remis à neuf avec des sols souples dans les pièces principales, du carrelage pour votre salle de bain et votre varangue et de la faïence dans la cuisine. Enfin pour vous protéger de la chaleur et apporter de la fraîcheur dans votre logement, des ventilateurs dans les séjours et les chambres seront ajoutés, des protections solaires seront installées en façades et des volets et des claustras seront posés.",
    jsxDescription: (
      <React.Fragment>
        <p>
          Tous les logements seront complètement réhabilités : de la porte
          d’entrée, aux tuyauteries jusqu’aux volets. Faisons un petit tour de
          votre futur logement ! Toutes les portes seront remplacées (Porte
          d’entrée et portes d’intérieur), les menuiseries extérieures, les
          équipements (Eviers, WC, Lavabo, baignoires remplacées par des
          douches, robinetterie…), les installations électriques (Tableau
          électrique, interrupteurs, prises, câbles sous goulottes…) et
          tuyauteries seront également remplacés. Les murs et les plafonds
          seront remis à neuf avec des sols souples dans les pièces principales,
          du carrelage pour votre salle de bain et votre varangue et de la
          faïence dans la cuisine. Enfin pour vous protéger de la chaleur et
          apporter de la fraîcheur dans votre logement, des ventilateurs dans
          les séjours et les chambres seront ajoutés, des protections solaires
          seront installées en façades et des volets et des claustras seront
          posés.
        </p>
      </React.Fragment>
    ),
    jsxNote: <p></p>,
    detailsTravaux: [
      {
        id: 1,
        emoji: "⚠️",
        detail:
          "Fort impact de l’occupation du logement pendant 1 mois non-stop.",
      },
      {
        id: 2,
        emoji: "🔊",
        detail: "Problème de bruits durant les travaux.",
      },
      {
        id: 3,
        emoji: "💨",
        detail: "Problème de poussières durant les travaux.",
      },
      {
        id: 4,
        emoji: "⚠️",
        detail: "Equipement fonctionnant de manière provisoire.",
      },
    ],
    thumbnail: "NC9Thumb.png",
    video: {
      url: "https://youtu.be/CF7Tu2CPCrU",
      width: 320,
      height: 400,
    },
    planMasse: "PlanMasse_NC9.png",
    planMasseTexte: "L’ensemble des logements.",
    duration: "18 mois.",
    periode: "2021 (Semestre 2) - 2022",
    tags: ["informative", "réhabilitation"],
    submitted: false,
    questions: {},
    comment: "",
    comments: [
      "un point important que les sols aient une meilleure isolation phonique j habite au premier étage et les bruits du dessus sont vraiment tres importants donc trés dérangeants",
      "Pourquoi pas le carrelage dans tous les pièces est mieux et facilite l'entretien",
      "On attend pour voir ",
      "2021",
      "Besoin douche et wc PMR",
      " ? ",
      "les claustras sont partout quel intérêt pourquoi supprimer nos fenêtres on a beaucoup d'aération naturelle actuellement ",
      "Aucune idée ",
      "Je suis ravi",
      "Besoin d'une fenêtre dans la chambre : problème d'humidité. Wc  PMR et douche PMR.",
      "Ok ",
    ],
    answers: [],
  },
];
