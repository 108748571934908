import React from "react";
import classes from "./Home.module.css";
// Images
// import BonHomme from "../../assets/images/bonhomme.png";
// Primary Components
import VisiteVirtuelle from "../../components/HomePage/VisiteVirtuelle/VisiteVirtuelle";
// import DescriptionSection from "../../components/HomePage/DescriptionSection/DescriptionSection";
import Blog from "../Blog/Blog";
import ContactForm from "../../components/Travaux/ContactForm/ContactForm";
import Footer from "../../components/Travaux/Footer/Footer";
// UI Componentns
import Separator from "../../components/UI/Separator/Separator";
// import LongButton from "../../components/UI/Buttons/LongButton/LongButton";
// Layout
import Layout from "../../hoc/Layout/Layout";
// MUI
import { Button } from "@material-ui/core";
// Youtube
import ReactPlayer from "react-player";

const Home = (props) => {
  const banner = require(`../../assets/homePage/banner_SHLMR mobile.png`);
  const GrandesEtapes = require(`../../assets/homePage/Calendar_Block.svg`);
  const carouselOne = require(`../../assets/homePage/ADSL_00.png`);
  const carouselTwo = require(`../../assets/homePage/ADSL_01.png`);
  const carouselThree = require(`../../assets/homePage/ADSL_02.png`);
  const ParticiperHandler = () => {
    props.history.push("/resultats");
  };
  const blogPostHandler = () => {
    props.history.push("/blog/1");
  };

  return (
    <Layout>
      <div className={classes.HomeCardBackground}>
        <div className={classes.HomeCardContent}>
          <div className={classes.HeroImage}>
            <img alt="Planning" src={banner} />
          </div>

          <div className={classes.Section}>
            <div className={classes.SectionTitle}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                👪
              </span>
              <h3>Un projet en concertation avec les locataires</h3>
            </div>

            <div className={classes.SectionSubtitle}>
              <p>
                Pour mener à bien ce projet ambitieux de réhabilitation la SHLMR
                a décidé de mettre les locataires à contribution dans une
                démarche de concertation inédite.
              </p>
            </div>
            <div className={classes.EmojiText}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                👉
              </span>
              <p>
                Durant les mois à venir, vous pourrez choisir et personnaliser
                certains travaux prévus dans le projet de réhabilitation grâce à{" "}
                <strong>l’application corehab</strong>. Cette application vous
                permettra par ailleurs de vous renseigner sur l’ensemble des
                travaux qui auront lieux dans votre logement ou dans votre
                résidence. Pour commencer, cliquez sur je participe juste en
                dessous.
              </p>
            </div>
            <div className={classes.YoutubePlayer}>
              <ReactPlayer
                url="https://youtu.be/IJN8cgTKFd8"
                loop={true}
                width={320}
                height={400}
              />
            </div>
            {/* <div className={classes.ParticiperButton}>
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                onClick={ParticiperHandler}
              >
                résultats
              </Button>
            </div> */}

            <div className={classes.HighlightNote}>
              <span></span>
              <p>
                Plus que jamais, votre avis compte et nous vous attendons
                nombreux sur la plateforme Victoria.corehab.fr !
              </p>
            </div>

            <div className={classes.EmojiText} style={{ marginTop: "1.5em" }}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                👉
              </span>
              <p>
                <strong>
                  Deux thématiques ont été choisies pour cette concertation :
                  Votre logement et votre résidence.
                </strong>
                <br />
                <br />
                Dans la thématique logement, vous pouvez choisir parmi des
                options de travaux prévus. La SHLMR garantie que vos choix dans
                l’application seront respectés pour la réalisation des travaux
                dans votre logement. Dans la thématique résidence, vous pouvez
                donner votre avis sur les grandes orientations d’aménagement des
                espaces extérieurs. Les résultats des participations guideront
                les architectes et la SHLMR dans leurs choix de travaux.
              </p>
            </div>
            <div className={classes.EmojiText} style={{ marginTop: "1.5em" }}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                👉
              </span>
              <p>
                <strong>
                  N’hésitez pas à venir visiter en VR le logement témoin !
                </strong>
                <br />
              </p>
            </div>

            <VisiteVirtuelle />
          </div>

          <div className={classes.Section} style={{ marginTop: "2em" }}>
            <div className={classes.EmojiText}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                👉
              </span>
              <p>
                <strong>
                  Question: Moi, ou un autre locataire de la résidence ne
                  possède ni ordinateur, ni tablette ni smartphone pour se
                  connecter sur le site Victoria.corehab.fr, puis-je ou peut-il
                  tout de même donner son avis et se renseigner sur les travaux
                  de réhabilitation?
                </strong>
                <br />
                <br />
                2 choix s’offres à vous : <br />
                <br />- L’association ADSL vous accueille du lundi au vendredi
                de 08h à 12h et de 13h à 16h dans le LCR du bâtiment Q
              </p>
            </div>
          </div>
          <div
            className={classes.Section}
            style={{ marginTop: "2em", marginLeft: "0", marginRight: "0" }}
          >
            <div className={classes.CarouselSection}>
              <div
                className={classes.CarouselItem}
                style={{
                  backgroundImage: `url(${carouselOne})`,
                  backgroundPosition: "right",
                }}
              ></div>
              <div
                className={classes.CarouselItem}
                style={{
                  backgroundImage: `url(${carouselTwo})`,
                  backgroundPosition: "right",
                }}
              ></div>

              <div
                className={classes.CarouselItem}
                style={{
                  backgroundImage: `url(${carouselThree})`,
                  backgroundPosition: "right",
                }}
              ></div>
            </div>
          </div>
          <div
            className={classes.Section}
            style={{ marginTop: "2em", marginBottom: "2em" }}
          >
            <div className={classes.EmojiText}>
              <p>
                - Notre MOUS (Maitrise d’œuvre Urbaine & Sociale en la personne
                de Sarah SAUTRON vous accueille lors des ses permanences sur
                site (2 demi-journées à programmer) dans le logement 139 de la
                résidence. Elle est joignable au 0692 30 75 78 pour prendre rdv.
              </p>
            </div>
          </div>

          <div className={classes.Section} style={{ marginTop: "1.5em" }}>
            <div className={classes.SectionTitle}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                📰
              </span>
              <h3>Les dernières informations</h3>
            </div>

            <div className={classes.SectionSubtitle}>
              <p>
                Vous trouverez ici toutes les dernières actualités liées au
                projet et à la concertation locataires.
              </p>
            </div>

            <Blog blogPostHandler={blogPostHandler} />
          </div>
          <div className={classes.Section}>
            <div className={classes.SectionTitle}>
              <span className={classes.Emoji} role="img" aria-label="emoji">
                📅
              </span>
              <h3 style={{ fontSize: "1.2rem" }}>
                Les grandes étapes prévisionnelles du projet de réhabilitation
                et de réaménagements des espaces extérieurs
              </h3>
            </div>

            <div className={classes.SectionSubtitle}>
              <p>
                Trouvez ici toutes les étapes du projet de réhabilitation. Nous
                mettrons à jour régulièrement ce calendrier pour mieux vous
                informer.
              </p>
            </div>

            <div className={classes.GrandesEtapes}>
              <img alt="Planning" src={GrandesEtapes} />
            </div>
          </div>
          <Separator />
          <div className={classes.ContactForm}>
            <ContactForm />
          </div>
        </div>
      </div>

      <div className={classes.Footer}>
        <Footer />
      </div>
    </Layout>
  );
};

export default Home;
