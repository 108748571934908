import React from "react";
import classes from "./BlogCard.module.css";
// UI Components
import LongButton from "../../UI/Buttons/LongButton/LongButton";
import { Button } from "@material-ui/core";

const BlogCard = ({ title, description, date, imgUrl, blogPostHandler }) => {
  const pont = require(`../../../assets/homePage/${imgUrl}`);
  return (
    <div className={classes.BlogCard}>
      <div
        className={classes.BlogCardImage}
        style={{
          backgroundImage: `url(${pont})`,
          backgroundPosition: "right",
        }}
      ></div>
      <div className={classes.BlogCardDate}>
        <p>{date}</p>
      </div>
      <div className={classes.BlogCardContent}>
        <h3 className={classes.BlogCardTitle}>{title}</h3>
        <p style={{ marginTop: "1em" }}>{description}</p>
      </div>
      <div className={classes.BlogCardButton}>
        <Button color="secondary" size="small" onClick={blogPostHandler}>
          <strong>en savoir plus</strong>
        </Button>
      </div>
    </div>
  );
};

export default BlogCard;
