import React, { useEffect, useRef } from "react";
import classes from "./PageOne.module.css";
// Images
// import BonHomme from "../../assets/images/bonhomme.png";
// Primary Components
// import DescriptionSection from "../../components/HomePage/DescriptionSection/DescriptionSection";
import ContactForm from "../../../components/Travaux/ContactForm/ContactForm";
import Footer from "../../../components/Travaux/Footer/Footer";
// UI Componentns
import Separator from "../../../components/UI/Separator/Separator";
// import LongButton from "../../components/UI/Buttons/LongButton/LongButton";
// Layout
import Layout from "../../../hoc/Layout/Layout";

const scrollToRef = (ref) => window.scrollTo(0, 0);

const PageOne = (props) => {
  const banner = require(`../../../assets/homePage/ADSL_BlogBanner00.png`);

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  useEffect(() => {
    executeScroll();
  }, []);

  return (
    <Layout>
      {/* <div className={classes.HeaderImage}>
        <img style={{ width: "100%" }} alt="header" src={illustrationAttente} />
      </div> */}
      <div className={classes.HeroImage}>
        <img alt="Planning" src={banner} />
      </div>

      <div className={classes.Section}>
        <div className={classes.SectionTitle} style={{ margin: "0 1em" }}>
          <h3>Quelques mots sur l’association ADSL</h3>
        </div>

        <div className={classes.SectionSubtitle}>
          <p>
            L'association ADSL créée en décembre 2016 est installée dans la
            Ville de Saint-André. Nous ne nous imposons pas de limites
            géographiques et souhaitons travailler avec toutes les communes et
            partenaires qui nous sollicitent.
          </p>
        </div>

        <div className={classes.HighlightNote}>
          <span></span>
          <p>
            Plus que jamais, votre avis compte et nous vous attondons nombreux
            sur la plateforme Victoria.corehab.fr !
          </p>
        </div>

        <div className={classes.EmojiText} style={{ marginBottom: "1.5em" }}>
          <p>
            <strong>Nous avons pour objet statutaire :</strong>
          </p>
        </div>

        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>Développer les quartiers par l’éducation populaire.</p>
        </div>
        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>
            Permettre à la population de s'épanouir par le sport, la culture et
            les loisirs.
          </p>
        </div>
        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>Accompagner au développement social, économique et personnel.</p>
        </div>
        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>Favoriser la cohésion sociale et la diversité culturelle.</p>
        </div>
        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>
            Accompagner et élaborer des projets de cadre de vie et de
            renouvellement urbain.
          </p>
        </div>
        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>
            Élaborer et animer des actions d'éducation (lutte contre
            l'illettrisme, ... )
          </p>
        </div>
        <div className={classes.EmojiText} style={{ marginBottom: "2em" }}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>
            Accompagner les porteurs de projets dans leurs démarches (écriture
            de projets, demande de subventions, etc.
          </p>
        </div>

        <div
          className={classes.SectionTitle}
          style={{ margin: "0 1em", marginBottom: "1em" }}
        >
          <h4>
            Grâce à un partenariat fort avec différentes institutions (Mairie,
            bailleurs sociaux, CCAS, CAF, Pôle emploi, Mission locale etc..)
            elle assure un accompagnement de proximité auprès de la population
            notamment dans les domaines :
          </h4>
        </div>

        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>Des démarches administratives</p>
        </div>
        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>
            Accès aux droits (aide au montage de dossiers, orientation et/ou
            accompagnement vers les services sociaux, suivi de dossiers)
          </p>
        </div>
        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>
            Accès à l'emploi et à la formation (accompagnement dans les
            démarches de recherche d'emploi ou de formation, écriture de C.V.,
            lettre de motivation etc,...)
          </p>
        </div>
        <div className={classes.EmojiText} style={{ marginBottom: "2em" }}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>
            Accès au logement (aide aux demandes de numéro unique, lien avec les
            bailleurs, etc...).
          </p>
        </div>

        <div
          className={classes.SectionTitle}
          style={{ margin: "0 1em", marginBottom: "1em" }}
        >
          <h4>
            Nous organisons également des actions éducatives sur différents
            thèmes :
          </h4>
        </div>

        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>Parentalité</p>
        </div>
        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>Lecture</p>
        </div>
        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>Santé</p>
        </div>
        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>Nutrition</p>
        </div>
        <div className={classes.EmojiText} style={{ marginBottom: "2em" }}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>Activités manuelles</p>
        </div>

        <div
          className={classes.SectionTitle}
          style={{ margin: "0 1em", marginBottom: "1em" }}
        >
          <h4>Des actions de sensibilisation et de prévention :</h4>
        </div>

        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>Addiction</p>
        </div>
        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>Sexualité</p>
        </div>
        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>Environnement</p>
        </div>

        <div className={classes.EmojiText} style={{ marginBottom: "2em" }}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>Citoyenneté</p>
        </div>
      </div>

      <div className={classes.Section}>
        <div className={classes.SectionSubtitle}>
          <p>
            L'association ADSL créée en décembre 2016 est installée dans la
            Ville de Saint-André. Nous ne nous imposons pas de limites
            géographiques et souhaitons travailler avec toutes les communes et
            partenaires qui nous sollicitent.
          </p>
        </div>

        <div className={classes.HighlightNote}>
          <span></span>
          <p>
            Nous nous efforçons de proposer ces actions à un public le plus
            large possible (enfants, parents, familles, etc...)
          </p>
        </div>

        <div className={classes.SectionSubtitle}>
          <p>
            Nous nous efforçons de proposer ces actions à un public le plus
            large possible (enfants, parents, familles, etc...) : Initiation à
            la musique, ateliers de remise en forme, initiations sportives,
            activités manuelles, atelier Danse, cuisine, etc... Dynamique et
            développement de quartier :
          </p>
        </div>

        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>Journée d'animation</p>
        </div>
        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>Tournois sportifs</p>
        </div>
        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>Journées culturelles</p>
        </div>

        <div className={classes.EmojiText} style={{ marginBottom: "2em" }}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>Journées récréatives</p>
        </div>

        <div className={classes.SectionSubtitle}>
          <p>
            Selon les caractéristiques du secteur, nous mettons en oeuvre des
            projets de développement urbain :
          </p>
        </div>

        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>Installation d’agrès de musculation</p>
        </div>
        <div className={classes.EmojiText}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>Gestion des espaces verts</p>
        </div>

        <div className={classes.EmojiText} style={{ marginBottom: "2em" }}>
          <span className={classes.Emoji} role="img" aria-label="emoji">
            👉
          </span>
          <p>Embellissement du quartier</p>
        </div>

        <div className={classes.SectionTitle} style={{ margin: "0 1em" }}>
          <h3>AIDE ALIMENTAIRE :</h3>
        </div>

        <div className={classes.SectionSubtitle}>
          <p>
            Depuis le mois de janvier 2020, nous sommes habilités à l’aide
            alimentaire d’urgence, nous avons donc ouvert une banque alimentaire
            au sein du LCR VICTORIA. Nous avons particulièrement été actifs dans
            le cadre du confinement lors duquel nous avons procédé à des
            distributions d’urgence aux habitants de toute la commune de
            Saint-André.
          </p>
        </div>
      </div>

      <div className={classes.Footer}>
        <Footer />
      </div>
    </Layout>
  );
};

export default PageOne;
