import React from "react";
import classes from "./Blog.module.css";
// ICONS
// import { Icon } from "@iconify/react";
// import newspaperIcon from "@iconify/icons-ion/newspaper";
// Primary Components
import BlogCard from "../../components/HomePage/BlogCard/BlogCard";
// Images

const Blog = (props) => {
  return (
    <div className={classes.Blog}>
      <BlogCard
        blogPostHandler={props.blogPostHandler}
        title="L’association ADSL"
        description="L'association ADSL créée en décembre 2016 est installée dans la Ville de Saint-André. Nous ne nous imposons pas de limites géographiques..."
        date="Le 23 juin 2020"
        imgUrl="ADSL_00.png"
      />
      {/* <BlogCard
        title="Visiter virtuellement le logement témoin!"
        description=""
        date="Le 23 juin 2020"
        imgUrl="articleTwo.gif"
      />
      <BlogCard
        title="Le BIM, une première pour la SHLMR en opération de réhabilitation."
        description=""
        date="Le 23 juin 2020"
        imgUrl="BIM3D.png"
      />
      <BlogCard
        title="Le projet Victoria au REX BIM Tour à la Réunion!"
        description=""
        date="Le 23 juin 2020"
        imgUrl="articleFour.png"
      /> */}
    </div>
  );
};

export default Blog;
