import React from "react";
import classes from "../ResultsConcertableLayout/ResultsConcertableLayout.module.css";
// MUI
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import { TextField, IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
// Counting animation
import CountUp from "react-countup";

const concertableQuestion = ({ question, handleChange, cardID, ilot }) => {
  return (
    <React.Fragment>
      <div className={classes.Questions}>
        <div className={classes.QuestionTitle}>
          <IconButton style={{ margin: 0, padding: 0 }}>
            <HelpOutlineRoundedIcon
              style={{ fontSize: 40, color: "#E0004D" }}
            />
          </IconButton>
          <h4>{question.title}</h4>
        </div>

        {ilot === 10
          ? question.options.map((option) => {
              return (
                <div className={classes.QuestionEnsemble} key={option.value}>
                  <p className={classes.QuestionPercentage}>
                    {((option.results * 100) / question.totResults).toFixed(0)}%
                    (<CountUp end={option.results} duration={5} />)
                  </p>
                  <p
                    className={
                      option.value === question.winner
                        ? classes.Winner
                        : classes.QuestionSentence
                    }
                  >
                    {option.label}
                  </p>
                </div>
              );
            })
          : ilot === 20
          ? question.options.map((option) => {
              return (
                <div className={classes.QuestionEnsemble} key={option.value}>
                  <p className={classes.QuestionPercentage}>
                    {(
                      (option.resultsIlotOne * 100) /
                      question.totResultsIlotOne
                    ).toFixed(0)}
                    % (<CountUp end={option.resultsIlotOne} duration={5} />)
                  </p>
                  <p
                    className={
                      option.value === question.winnerIlotOne
                        ? classes.Winner
                        : classes.QuestionSentence
                    }
                  >
                    {option.label}
                  </p>
                </div>
              );
            })
          : ilot === 30
          ? question.options.map((option) => {
              return (
                <div className={classes.QuestionEnsemble} key={option.value}>
                  <p className={classes.QuestionPercentage}>
                    {(
                      (option.resultsIlotTwo * 100) /
                      question.totResultsIlotTwo
                    ).toFixed(0)}
                    % (<CountUp end={option.resultsIlotTwo} duration={5} />)
                  </p>
                  <p
                    className={
                      option.value === question.winnerIlotTwo
                        ? classes.Winner
                        : classes.QuestionSentence
                    }
                  >
                    {option.label}
                  </p>
                </div>
              );
            })
          : question.options.map((option) => {
              return (
                <div className={classes.QuestionEnsemble} key={option.value}>
                  <p className={classes.QuestionPercentage}>
                    {(
                      (option.resultsIlotThree * 100) /
                      question.totResultsIlotThree
                    ).toFixed(0)}
                    % (<CountUp end={option.resultsIlotThree} duration={5} />)
                  </p>
                  <p
                    className={
                      option.value === question.winnerIlotThree
                        ? classes.Winner
                        : classes.QuestionSentence
                    }
                  >
                    {option.label}
                  </p>
                </div>
              );
            })}
      </div>
    </React.Fragment>
  );
};

export default concertableQuestion;
