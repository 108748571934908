import React, { useState, useEffect, useRef, useContext } from "react";
import classes from "./Resultats.module.css";
// Counting animation
import CountUp from "react-countup";
// Contexts
import { TravauxContext } from "../../contexts/TravauxContext";
import { AuthContext } from "../../contexts/AuthContext";
// Utils
import { loadResults } from "../../utils/ResultatsUtils";
// MUI components
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// Components
import Modal from "../../components/UI/Modal/Modal";
import IntroModal from "../../components/Travaux/HelpModals/IntroModal/IntroModal";
import Toolbar from "../../components/Travaux/Navigation/Toolbar/Toolbar";
import Filter from "../../components/Travaux/Filter/Filter";
import CardList from "../../components/Travaux/CardList/CardList";
import Footer from "../../components/Travaux/Footer/Footer";
import CardModal from "../../components/Travaux/CardModal/CardModal";
import CommentsModalLayout from "../../components/Travaux/ConcertableLayouts/ResultsConcertableLayout/CommentsModalLayout/CommentsModalLayout";
// Cards
import ResultsConcertableLayout from "../../components/Travaux/ConcertableLayouts/ResultsConcertableLayout/ResultsConcertableLayout";
import ResultsNonConcertableLayout from "../../components/Travaux/NonConcertableLayouts/ResultsNonConcertableLayout/ResultsNonConcertableLayout";

const scrollToRef = (ref) => window.scrollTo(0, 950);

const Travaux = () => {
  const { travauxData, dispatch } = useContext(TravauxContext);
  const { currentUser } = useContext(AuthContext);
  const { cards, selectedCard, showCard, filters } = travauxData;

  const [commentsModalShow, setCommentsModalShow] = useState(false);
  const [ilot, setIlot] = useState(10);

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  // Declare a new state variable with the "useState" Hook
  const [width, setWidth] = useState(window.innerWidth);

  // Card Element
  const cardElement = (
    <React.Fragment>
      {selectedCard !== "" && cards[selectedCard].type === "concertable" ? (
        <CardModal show={showCard}>
          <ResultsConcertableLayout
            ilot={ilot}
            setIlot={setIlot}
            card={cards[selectedCard]}
            dispatch={dispatch}
            width={width}
            currentUser={currentUser}
            setCommentsModalShow={setCommentsModalShow}
          />
        </CardModal>
      ) : selectedCard !== "" &&
        cards[selectedCard].type === "nonConcertable" ? (
        <CardModal show={showCard}>
          <ResultsNonConcertableLayout
            card={cards[selectedCard]}
            dispatch={dispatch}
            width={width}
            currentUser={currentUser}
            setCommentsModalShow={setCommentsModalShow}
          />
        </CardModal>
      ) : null}
    </React.Fragment>
  );

  // Dropdown Ilots
  const dropdownIlots = (
    <div className={classes.DropdownIlots}>
      <FormControl className={classes.formControl} style={{ minWidth: 220 }}>
        <InputLabel
          id="select-ilot"
          color="secondary"
          style={{ fontWeight: "bold" }}
        >
          Îlot (n° d'appartement)
        </InputLabel>
        <Select
          labelId="select-ilot"
          id="simple-select-ilot"
          value={ilot}
          onChange={(e) => setIlot(e.target.value)}
          color="secondary"
          autoWidth={false}
        >
          <MenuItem value={10}>Tous les îlots (001 -> 220)</MenuItem>
          <MenuItem value={20}>Îlot 1 (125 -> 186)</MenuItem>
          <MenuItem value={30}>Îlot 2 (001 -> 072)</MenuItem>
          <MenuItem value={40}>Îlot 3 (073 -> 123) (187 -> 220)</MenuItem>
        </Select>
      </FormControl>
    </div>
  );

  // Illustration Section
  const IllustrationSection = (
    <div className={classes.IllustrationsSection}>
      <p className={classes.CardListTitle}>
        <span>Résultats</span> de la co-construction.
      </p>

      {dropdownIlots}

      <div className={classes.IllustrationsContent}>
        <div className={classes.StatsIllustration}>
          <div className={classes.Illustration}>
            <img
              style={{ width: "100%" }}
              alt="Queue"
              src={require(`../../assets/resultats/undraw_queue.svg`)}
            />
          </div>
          <div className={classes.StatsContent}>
            <h3>Nombre de participants</h3>
            {ilot === 10 ? (
              <p>
                <CountUp end={144} duration={5} /> / 206 (
                <CountUp end={70} duration={5} />
                %)
              </p>
            ) : ilot === 20 ? (
              <p>
                <CountUp end={41} duration={5} /> / 61 (
                <CountUp end={67} duration={5} />
                %)
              </p>
            ) : ilot === 30 ? (
              <p>
                <CountUp end={50} duration={5} /> / 71 (
                <CountUp end={70} duration={5} />
                %)
              </p>
            ) : (
              <p>
                <CountUp end={53} duration={5} /> / 83 (
                <CountUp end={64} duration={5} />
                %)
              </p>
            )}
          </div>
        </div>
        <div className={classes.StatsIllustration}>
          <div className={classes.Illustration}>
            <img
              style={{ width: "70%" }}
              alt="Queue"
              src={require(`../../assets/resultats/undraw_reponse.svg`)}
            />
          </div>
          <div className={classes.StatsContent}>
            <h3>Nombre de réponses</h3>
            {ilot === 10 ? (
              <p>
                <CountUp end={959} duration={5} />
              </p>
            ) : ilot === 20 ? (
              <p>
                <CountUp end={273} duration={5} />
              </p>
            ) : ilot === 30 ? (
              <p>
                <CountUp end={332} duration={5} />
              </p>
            ) : (
              <p>
                <CountUp end={353} duration={5} />
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    /* Inside of a "useEffect" hook add an event listener that updates
       the "width" state variable when the window size changes */
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    // Load data
    // loadResults({ dispatch });

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);

    /* passing an empty array as the dependencies of the effect will cause this
       effect to only run when the component mounts, and not each time it updates.
       We only want the listener to be added once */
  }, []);

  if (width < 768) {
    return (
      <React.Fragment>
        <div ref={myRef}></div>
        {/* <Modal
          show={introModalShow}
          backgroundColor="#F3F2F0"
          clicked={() => setIntroModalShow(false)}
        >
          <IntroModal clicked={() => setIntroModalShow(false)} />
        </Modal> */}
        <Modal
          show={commentsModalShow}
          clicked={() => setCommentsModalShow(false)}
        >
          <CommentsModalLayout card={cards[selectedCard]} />
        </Modal>

        {cardElement}

        <div className={classes.Travaux}>
          <Toolbar />

          {IllustrationSection}

          <div className={classes.Main}>
            <Filter filters={filters} dispatch={dispatch} />
            <p className={classes.CardListTitle}>
              <span>Consulter</span> les résultats des fiches de travaux
            </p>
            <CardList
              filters={filters}
              cards={cards}
              dispatch={dispatch}
              executeScroll={executeScroll}
            />
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {/* <Modal
          show={introModalShow}
          backgroundColor="#F3F2F0"
          clicked={() => setIntroModalShow(false)}
        >
          <IntroModal clicked={() => setIntroModalShow(false)} />
        </Modal> */}
        <Modal
          show={commentsModalShow}
          clicked={() => setCommentsModalShow(false)}
        >
          <CommentsModalLayout card={cards[selectedCard]} />
        </Modal>

        <div className={classes.Travaux}>
          <Toolbar />

          {IllustrationSection}

          <div className={classes.Main}>
            <Filter filters={filters} dispatch={dispatch} />
            <p className={classes.CardListTitle}>
              <span>Consulter</span> les résultats des fiches travaux
            </p>
            <div className={classes.MainCards}>
              <div className={classes.List}>
                <CardList
                  filters={filters}
                  cards={cards}
                  dispatch={dispatch}
                  executeScroll={executeScroll}
                />
              </div>
              <div className={classes.OpenedCard}>
                <div className={classes.NoCard} ref={myRef}>
                  <span>Veuillez sélectionner une carte </span>
                </div>
                {cardElement}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
};

export default Travaux;
