import React from "react";
import classes from "./RefInfo.module.css";
import Auxiliary from "../../../hoc/Auxiliary";
// Icons
// npm install --save-dev @iconify/react @iconify/icons-dashicons
import { Icon } from "@iconify/react";
import mediaDocument from "@iconify/icons-dashicons/media-document";

const refInfo = (props) => {
  return (
    <Auxiliary>
      <div className={classes.RefInfoTitle}>
        <Icon icon={mediaDocument} width="25px" />
        <h3>Où trouver votre numéro de référence locataire ?</h3>
      </div>

      <p>
        <br /> Votre numéro de référence locataire se trouve sur votre avis
        mensuel d’échéance. Le renseigner ici, nous permet de sécuriser votre
        authentification.
      </p>

      {/* <div className={classes.RefWrapper}>
        <img
          src={require("../../../assets/images/referenceInfo.jpg")}
          alt="ReferenceInfo"
        />
      </div> */}
    </Auxiliary>
  );
};

export default refInfo;
