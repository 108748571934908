import React from "react";
import classes from "../ConcertableLayoutOne/concertableLayoutOne.module.css";
// MUI
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import { TextField, IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";

const concertableQuestion = ({ question, handleChange, cardID }) => {
  let questionElement = null;

  switch (question.type) {
    case "radio":
      questionElement = (
        <div className={classes.Questions}>
          <div className={classes.QuestionTitle}>
            <IconButton style={{ margin: 0, padding: 0 }}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 40, color: "#E0004D" }}
              />
            </IconButton>
            <p>{question.title}</p>
          </div>

          <div className={classes.MUIForm}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label={question.id}
                name={question.id}
                value={question.value}
                onChange={(event) =>
                  handleChange(event, question.id, cardID, question.type)
                }
              >
                {Object.keys(question.options).map((option, indexOption) => (
                  <FormControlLabel
                    key={question.options[option].value}
                    value={question.options[option].value}
                    control={<Radio />}
                    label={question.options[option].label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      );
      break;

    case "radioImage":
      questionElement = (
        <div className={classes.Questions}>
          <div className={classes.QuestionTitle}>
            <IconButton style={{ margin: 0, padding: 0 }}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 40, color: "#E0004D" }}
              />
            </IconButton>
            <p>{question.title}</p>
          </div>
          <form className={classes.RadioForm}>
            {Object.keys(question.options).map((option) => (
              <div
                className={classes.RadioElement}
                key={question.options[option].value}
              >
                <input
                  type="radio"
                  name={question.id}
                  value={question.options[option].value}
                  onChange={(event) =>
                    handleChange(event, question.id, cardID, question.type)
                  }
                  id={question.options[option].value}
                  checked={question.value === question.options[option].value}
                />
                <label htmlFor={question.options[option].value}>
                  <img
                    alt={question.options[option].thumbnail}
                    src={require(`../../../../assets/travaux/miniatures/${question.options[option].thumbnail}`)}
                  />
                  <p>{question.options[option].label}</p>
                </label>
              </div>
            ))}
          </form>
        </div>
      );

      break;

    case "input":
      questionElement = (
        <div className={classes.Questions}>
          <div className={classes.QuestionTitle}>
            <IconButton style={{ margin: 0, padding: 0 }}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 40, color: "#E0004D" }}
              />
            </IconButton>
            <p>{question.title}</p>
          </div>
          <div className={classes.CommentSection}>
            <TextField
              variant="outlined"
              style={{ marginBottom: "0.8em", width: "100%" }}
              color="secondary"
              label={question.label}
              value={question.value}
              onChange={(event) =>
                handleChange(event, question.id, cardID, question.type)
              }
            />
          </div>
        </div>
      );
      break;
    case "conditional":
      questionElement = (
        <div className={classes.Questions}>
          <div className={classes.QuestionTitle}>
            <IconButton style={{ margin: 0, padding: 0 }}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 40, color: "#E0004D" }}
              />
            </IconButton>
            <p>{question.title}</p>
          </div>

          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>Non</Grid>
              <Grid item>
                <Switch
                  checked={question.value}
                  onChange={(event) =>
                    handleChange({
                      type: "SET_CONDITIONAL",
                      value: event.target.checked,
                      cardID: cardID,
                    })
                  }
                  name={question.id}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </Grid>
              <Grid item>Oui</Grid>
            </Grid>
          </Typography>
        </div>
      );
      break;
    default:
      questionElement = <div>Erreur</div>;
      break;
  }

  return <React.Fragment>{questionElement}</React.Fragment>;
};

export default concertableQuestion;
