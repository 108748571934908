import React from "react";
import classes from "./CommentsModalLayout.module.css";

const commentsModalLayout = ({ card }) => {
  return (
    <div className={classes.CommentSection}>
      <div className={classes.Comment}>
        <h3>Commentaire #1</h3>
        <p>Ceci est un commentaire</p>
      </div>
      <div className={classes.Comment}>
        <h3>Commentaire #2</h3>
        <p>Ceci est un commentaire</p>
      </div>
    </div>
  );
};

export default commentsModalLayout;
