import React, { useState, useEffect } from "react";
import classes from "./VisiteVirtuelle.module.css";
// Youtube
import ReactPlayer from "react-player";
// MUI
import Button from "@material-ui/core/Button";

const VisiteVirtuelle = () => {
  // Declare a new state variable with the "useState" Hook
  const [width, setWidth] = useState(window.innerWidth);

  // redirect

  useEffect(() => {
    /* Inside of a "useEffect" hook add an event listener that updates
       the "width" state variable when the window size changes */
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  if (width < 768) {
    return (
      <div className={classes.YoutubePlayer}>
        <Button variant="contained" color="secondary" size="medium">
          <a
            target="_blank"
            href="https://youtu.be/awLX6BoM4Dk"
            style={{ color: "white", textDecoration: "none" }}
          >
            Visite Virtuelle
          </a>
        </Button>
      </div>
    );
  } else {
    return (
      <div className={classes.YoutubePlayer}>
        <ReactPlayer url="https://youtu.be/awLX6BoM4Dk" controls={true} />
      </div>
    );
  }
};

export default VisiteVirtuelle;
